/**
 * getViewportWidth
 *
 * @returns {number} width of viewport
 *
 * This function returns the width of the viewable area of the browser.
 * It takes into account any scrollbars and returns the maximum value between the document element's client width and the window's inner width.
 */
function getViewportWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

/**
 * getViewportHeight
 *
 * @returns {number} height of viewport
 *
 * This function returns the height of the viewable area of the browser.
 * It takes into account any scrollbars and returns the maximum value between the document element's client height and the window's inner height.
 */
function getViewportHeight() {
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
}

const canvasEl = document.getElementById("dotCanvas");
const context = canvasEl.getContext("2d");

let vw = getViewportWidth(),
  vh = getViewportHeight();

/**
 * onResize
 *
 * This function updates the width and height of the viewport and resizes the canvas when the browser window is resized.
 */
window.addEventListener("resize", onResize, false);
function onResize() {
  vw = getViewportWidth();
  vh = getViewportHeight();
  resizeCanvas();
}

/**
 * resizeCanvas
 *
 * This function sets the width and height of the canvas to the width and height of the viewport and redraws the dots.
 */
function resizeCanvas() {
  canvasEl.width = vw;
  canvasEl.height = vh;
  drawDots();
}
resizeCanvas();

/**
 * drawGrid
 *
 * This function draws the grid on the canvas. It uses gridCellWidth and gridCellHeight to determine the size of each cell, and draws vertical and horizontal lines using gridX and gridY respectively.
 */
function drawGrid() {
  const gridCellWidth = 4,
    gridCellHeight = 4;

  // vertical lines
  for (let x = 0; x <= vw; x += gridCellWidth) {
    context.moveTo(x, 0); // x, y
    context.lineTo(x, vh);
  }

  // horizontal lines
  for (let y = 0; y <= vh; y += gridCellHeight) {
    context.moveTo(0, y); // x, y
    context.lineTo(vw, y);
  }

  context.strokeStyle = "#cccccc";
  context.stroke();
}

/**
 * scrollEvent
 *
 * This function sets the position of the canvas element based on the current scroll position of the page.
 * It uses the scrollY property to get the current scroll position and sets the transform property of the canvas element to match it.
 */
// const scrollIcon = document.querySelector(".home-scroll");
// window.addEventListener("scroll", function () {
//   // Get the current scroll position
//   let scrollPosition = window.scrollY;
//   // Set the position of the dots based on the scroll position
//   canvasEl.style.transform = `translateY(${scrollPosition}px)`;
// });

/**
    drawDots

    This function draws dots on the canvas. It uses dotRadius to determine the size of each dot, and dotSpacingX and dotSpacingY to determine the distance between each dot.
    */
function drawDots() {
  const dotRadius = 1,
    dotSpacingX = 10,
    dotSpacingY = 10;

  for (let gridX = 0; gridX < vw; gridX += dotSpacingX) {
    for (let gridY = 0; gridY < vh; gridY += dotSpacingY) {
      context.fillStyle = "rgba(133, 128, 80, .2)";
      context.fillRect(
        gridX - dotRadius / 2,
        gridY - dotRadius / 2,
        dotRadius,
        dotRadius
      );
    }
  }
}
drawDots();
